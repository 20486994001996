<template>
  <div>
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="tab" v-if="false">
        <div :class="loginMode == 'BUYER' ? 'tab-item tab-item-active' : 'tab-item'" @click="loginModeSwitch('BUYER')">
          <span class="tab-item-text">试客登录</span>
        </div>
        <div
          :class="loginMode == 'SELLER' ? 'tab-item tab-item-active' : 'tab-item'"
          @click="loginModeSwitch('SELLER')"
        >
          <span class="tab-item-text">商家登录</span>
        </div>
      </div>

      <div class="form">
        <el-form ref="formData" :model="formData" :rules="formRules" @submit.prevent="formSubmit('formData')">
          <el-form-item prop="loginName">
            <el-input
              v-model.trim="formData.loginName"
              @keyup.enter.native="formSubmit('formData')"
              placeholder="用户名"
              size="small"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model.trim="formData.password"
              @keyup.enter.native="formSubmit('formData')"
              placeholder="密码"
              size="small"
              type="password"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="formSubmit('formData')" size="small" style="width: 100%">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="pc-action">
        <div class="action-item">
          <router-link to="/password">忘记密码？</router-link>
        </div>
        <div class="action-item">
          <router-link to="/register">没有账号，去注册 ></router-link>
        </div>
      </div>

      <div class="mob-action">
        <div class="action-item">
          <el-button type="primary" size="small" style="width: 100%"
            ><router-link to="/password">忘记密码？</router-link></el-button
          >
        </div>
        <div class="action-item">
          <el-button type="primary" size="small" style="width: 100%"
            ><router-link to="/register">没有账号，去注册 ></router-link></el-button
          >
        </div>
      </div>
    </div>

    <div v-if="!isMobile && showSidebar" class="fixed right-10 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col">
      <!-- QQ 在线咨询 -->
      <a
        :href="'http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=' + systemConfig.CUSTOMER_SERVICE_QQ"
        target="_blank"
        rel="noopener noreferrer"
        class="text-center px-4 py-2 border-bottom-1 border-dashed"
      >
        <img src="@/assets/image/qq.png" alt="QQ在线咨询" title="QQ在线咨询" class="h-95px mx-auto mb-2" />
        QQ在线咨询
      </a>
      <!-- 下载 APP -->
      <a target="_blank" rel="noopener noreferrer" class="text-center px-4 py-2 border-bottom-1 border-dashed">
        <img :src="systemConfig.CUSTOMER_SERVICE_WX_IMG" alt="下载APP" title="下载APP" class="h-95px mx-auto mb-2" />
        微信咨询
      </a>
      <!-- 关闭 -->
      <div class="text-center px-4 py-2 text-gray-500" @click="showSidebar = false">关闭</div>
    </div>

    <div v-if="isMobile && showSidebar" class="fixed flex-col right-0 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col" 
      style="background-color: lightgray; align-items: center; border-radius: 3px; padding: 8px 4px;"
      @click="showCustomerDialog">
      <img src="@/assets/image/qq-icon.png" alt="QQ在线咨询" title="QQ在线咨询" style="width: 20px; height: 20px;" />
      <span style="writing-mode: vertical-rl; color: black; font-size: 15px; font-weight: bold;">在线咨询</span>
    </div>

    <el-dialog title="联系客服" :visible.sync="dialogVisible" @close="dialogVisible" width="80%" center>
      <div class="flex-row" style="align-items: center; ">
        <span>QQ：{{systemConfig.CUSTOMER_SERVICE_QQ}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_QQ)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px;">
        <span>微信：{{systemConfig.CUSTOMER_SERVICE_WX}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_WX)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px; color: red; font-size: 15px;">
        悟空给的佣金高过大多同类平台，所以请珍惜认真按要求做任务，不然可能禁号
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { login } from "@/api/user";

export default {
  data() {
    return {
      loginMode: "BUYER", //BUYER,SELLER
      showSidebar: true,
      isMobile: false,
      dialogVisible: false,
      formData: {
        loginName: "",
        password: "",
      },
      formRules: {
        loginName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 520;
  },
  methods: {
    showCustomerDialog() {
      this.dialogVisible = true
    },
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data } = await login({
            ...this.formData,
            userRole: this.loginMode,
          });
          this.$store.commit("user/LOGIN", data);
          this.$message.success("登录成功");
          setTimeout(() => {
            if (this.loginMode == "BUYER") this.toIndex();
            else this.toTaskList();
          }, 400);
        } else {
          return false;
        }
      });
    },
    // tab切换
    loginModeSwitch(mode) {
      this.loginMode = mode;
      console.log("当前登录模式：", this.loginMode);
    },
    toIndex() {
      this.$router.push({ path: "/trial" });
    },
    toTaskList() {
      this.$router.push({ path: "/seller/trial" });
    },
  },
};
</script>

<style lang="less" scoped>
.mb-2rem {
  margin-bottom: 2rem;
}
.plr-2rem {
  padding: 0 2rem;
}

.login-box {
  width: 360px;
  text-align: center;
  padding: 20px 0px 30px 0;
  border-radius: 7px;
  background-color: #ffffff;

  .logo {
    .mb-2rem;
    img {
      width: 60%;
      height: auto;
      display: inline;
    }
  }
  .tab {
    display: flex;
    justify-content: center;
    .mb-2rem;

    .tab-item {
      width: 50%;
      cursor: pointer;
    }
    .tab-item-active {
      .tab-item-text {
        position: relative;
        color: #1684fc;
        &::after {
          position: absolute;
          bottom: -1rem;
          left: 0;
          content: "";
          width: 100%;
          height: 2px;
          background-color: #1684fc;
        }
      }
    }
  }
  .form {
    .plr-2rem;
  }
  .pc-action {
    display: flex;
    justify-content: space-between;
    .plr-2rem;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  .mob-action {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .plr-2rem {
    padding: 0 1rem;
  }
  .login-box {
    .form {
      padding: 0 1rem;
      /deep/ .el-form-item {
        margin-bottom: 0.5rem;
      }
    }
    .pc-action {
      display: none;
    }
    .mob-action {
      padding: 0 1rem;
      display: block;
      .action-item {
        margin-bottom: 0.5rem;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
