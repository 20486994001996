<template>
  <div class="c_main">
    <el-form ref="formData" :model="formData" :rules="formRule" label-width="140px">
      <el-form-item label="需要审核：" prop="receiveNeedAudit">
        <el-radio-group v-model="formData.receiveNeedAudit">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">任务是否需要您审核通过才能进行</span>
      </el-form-item>

      <el-form-item label="接任务限制：" prop="repeatTookInterval">
        <el-radio-group v-model="formData.repeatTookInterval">
          <el-radio :label="0">不限制</el-radio>
          <!-- <el-radio :label="1">1天</el-radio> -->
          <el-radio :label="2">2天</el-radio>
          <el-radio :label="3">3天</el-radio>
          <el-radio :label="4">4天</el-radio>
          <el-radio :label="5">5天</el-radio>
          <el-radio :label="10">10天</el-radio>
          <el-radio :label="15">15天</el-radio>
          <el-radio :label="30">30天</el-radio>
          <el-radio :label="45">45天</el-radio>
        </el-radio-group>
        <div class="help_block">多少天内，同一买号禁止申请同一个掌柜的任务</div>
      </el-form-item>

      <el-form-item label="禁止地区：">
        <el-button @click="addRegionFun" type="primary" size="small">增加地区</el-button>
        <span class="help">禁止某一地区的试客申请</span>

        <div class="searchkeyword-form-item" v-for="(tpl, tpl_index) in region" :key="tpl_index">
          <div class="searchkeyword-form-item-left">
            <el-cascader class="form-input" size="small" v-model="tpl.value" :options="regionOptions"></el-cascader>
          </div>
          <div class="searchkeyword-form-item-del">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              circle
              @click="deleteRegionFun(tpl_index)"
            ></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="是否商保会员：" prop="needCommercialInsurance">
        <el-radio-group v-model="formData.needCommercialInsurance" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_SHANG_BAO_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="买号信用要求：" prop="creditLevel" v-if="platformType != 'PDD'">
        <el-radio-group v-model="formData.creditLevel" v-if="platformType == 'TAO_BAO'" @change="refreshPrice">
          <el-radio :label="0">不限信誉</el-radio>
          <!-- <el-radio :label="1">1心</el-radio>
          <el-radio :label="2">2心</el-radio> -->
          <el-radio :label="3">3心</el-radio>
          <el-radio :label="4">4心</el-radio>
          <el-radio :label="5">5心</el-radio>
          <el-radio :label="6">1钻</el-radio>
          <el-radio :label="7">2钻</el-radio>
          <el-radio :label="8">3钻</el-radio>
          <el-radio :label="9">4钻</el-radio>
          <el-radio :label="10">5钻</el-radio>
          <el-radio :label="11">冠级</el-radio>
        </el-radio-group>
        <el-radio-group v-model="formData.creditLevel" v-if="platformType == 'JD'" @change="refreshPrice">
          <el-radio :label="0">不限信誉</el-radio>
          <el-radio :label="1">铜牌</el-radio>
          <el-radio :label="2">银牌</el-radio>
          <el-radio :label="3">金牌</el-radio>
          <el-radio :label="4">钻石</el-radio>
        </el-radio-group>
        <el-radio-group v-model="formData.creditLevel" v-if="platformType == 'DOU_YIN'" @change="refreshPrice">
          <el-radio :label="0">不限信誉</el-radio>
          <el-radio :label="1">1心</el-radio>
          <el-radio :label="2">2心</el-radio>
          <el-radio :label="3">3心</el-radio>
          <el-radio :label="4">4心</el-radio>
          <el-radio :label="5">5心</el-radio>
          <el-radio :label="6">1钻</el-radio>
        </el-radio-group>
        <div class="help_block">{{ systemConfig.TASK_XIN_YONG_JIA_GE }}</div>
      </el-form-item>

      <el-form-item label="买号淘气值要求：" prop="taoScoreNum" v-if="platformType == 'TAO_BAO'">
        <el-radio-group v-model="formData.taoScoreNum">
          <el-radio :label="0">不限</el-radio>
          <el-radio :label="100">100以上</el-radio>
          <el-radio :label="200">200以上</el-radio>
          <el-radio :label="300">300以上</el-radio>
          <el-radio :label="400">400以上</el-radio>
          <el-radio :label="500">500以上</el-radio>
          <el-radio :label="600">600以上</el-radio>
          <el-radio :label="700">700以上</el-radio>
          <el-radio :label="800">800以上</el-radio>
        </el-radio-group>
        <div class="help_block">不建议淘气值要求太高，没多大意义。不是特别产选不限，让其自然分配。</div>
      </el-form-item>

      <el-form-item label="买号性别要求：" prop="sexs">
        <el-checkbox-group v-model="sexs">
          <el-checkbox :label="1">男号</el-checkbox>
          <el-checkbox :label="0">女号</el-checkbox>
        </el-checkbox-group>
        <span class="help">不选和双选-表示不限制性别</span>
      </el-form-item>

      <el-form-item label="任务回收时间：" prop="taskRecycleTimeLimit">
        <el-radio-group v-model="formData.taskRecycleTimeLimit">
          <el-radio :label="2">2小时</el-radio>
          <el-radio :label="3">3小时</el-radio>
          <el-radio :label="8">8小时</el-radio>
          <el-radio :label="12">12小时</el-radio>
          <el-radio :label="24">24小时</el-radio>
          <el-radio :label="48">48小时</el-radio>
          <el-radio :label="99">当天24点</el-radio>
        </el-radio-group>
        <div class="help_block">超过设定时间后没人接任务，此任务会被自动回收，并返还扣除的资金</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { regionList } from "@/api/user";
export default {
  props: {
    forms: Array,
  },
  data() {
    return {
      formData: {
        receiveNeedAudit: 0,
        repeatTookInterval: 30,
        needCommercialInsurance: 0,
        creditLevel: 0,
        taoScoreNum: 0,
        taskRecycleTimeLimit: 24,
      },
      formRule: {
        receiveNeedAudit: [{ required: true, message: "请选择是否需要审核", trigger: "change" }],
        repeatTookInterval: [{ required: true, message: "请选择最大接单数", trigger: "change" }],
        needCommercialInsurance: [{ required: true, message: "请选择是否需要商保", trigger: "change" }],
        creditLevel: [{ required: true, message: "请选择信誉等级", trigger: "change" }],
        taoScoreNum: [{ required: true, message: "请选择淘气值要求", trigger: "change" }],
        taskRecycleTimeLimit: [{ required: true, message: "请选择任务回收时间", trigger: "change" }],
      },
      regionOptions: [],
      sexs: [],
      region: [],
    };
  },
  computed: {
    platformType() {
      return this.forms[0].formData.platformType;
    },
    systemConfig() {
      return this.$store.state.config.config;
    },
    // priceConfig() {
    //   return this.$store.state.config.priceConfig;
    // },
  },
  mounted() {
    if (Object.keys(this.forms[2].formData).length > 0) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.forms[2].formData[key];
      });
      this.sexs = this.formData.sexs.split(",");
      this.region =
        this.formData.forbidDistrictCodes == ""
          ? []
          : this.formData.forbidDistrictCodes.split(",").map((item) => {
              return { value: item.split(",") };
            });
    }
  },
  methods: {
    // 表单提交，父组件访问此方法，如果表单验证成功返回表单数据，否则返回null
    async submitForm(formName) {
      if (!formName) formName = "formData";
      const result = await this.$refs[formName].validate();
      if (result) {
        return {
          ...this.formData,
          forbidDistrictCodes: this.region.map((item) => item.value[2]).join(","),
          sexs: this.sexs.join(","),
        };
      } else {
        return false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addRegionFun() {
      this.region.push({ value: "" });
      if (this.regionOptions.length == 0) {
        this.getRegionList();
      }
    },
    deleteRegionFun(i) {
      this.region.splice(i, 1);
    },
    async getRegionList() {
      const { data } = await regionList();
      this.regionOptions = data;
    },
    refreshPrice() {
      this.$emit("refreshPrice", this.formData);
    },
  },
};
</script>

<style lang="less" scoped>
.c_main {
  .form-input {
    width: 200px;
  }

  .help {
    padding: 0 1rem;
    color: #999;
  }
  .help_block {
    color: #999;
  }

  .searchkeyword-form-item {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .searchkeyword-form-item-left {
      width: 240px;
    }
    .searchkeyword-form-item-right {
      width: 340px;
    }
    .searchkeyword-form-item-del {
      width: 50px;
    }
  }
}

@media (max-width: 520px) {
  .c_main {
    .help {
      display: block;
      padding: 0;
    }

    .el-radio {
      margin-bottom: 15px;
    }
  }
}
</style>
